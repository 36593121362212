.donation-container {
  margin: 80px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.donation-box {
  width: 500px;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.donation-box h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.selection-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.selection-box {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selection-box:hover:not(.selected) {
  background: #e7e7e7;
  color: #000;
}
.selection-box.selected {
  background: #c5fffb;
  border: 2px solid #0492ae;
  color: #014957;
}

.selection-box img {
  width: 50%;
  height: auto;
  mix-blend-mode: multiply;
}

.selection-box:last-child img,
.selection-box:first-child img {
  width: 70%;
}

.selection-box p {
  font-size: 16px;
  font-weight: 600;
}

.number-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.number-box {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.number-box:hover {
  background: #e7e7e7;
}

.number-box p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.total-amount-box {
  margin-top: 15px;
  padding: 10px;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

.total-amount-box p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007991;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #005f6b;
}
