/* General Styles */
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  text-align: center;
  overflow: hidden;
}

.not-found-content {
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
}

/* Text Animations */
.not-found-text {
  font-size: 10rem;
  margin: 0;

}

.not-found-subtext {
  font-size: 2.5rem;
  animation: fadeIn 2s ease-in-out;
  margin: 0;
}

.not-found-description {
  font-size: 1.2rem;
  animation: fadeIn 3s ease-in-out;
  color: #007991;
}

/* Icon Animations */
.not-found-icon {
  font-size: 5rem;
  color: #007991;
}

.back-home-icon {
  margin-right: 10px;
}

/* Link Styling */
.back-home-link {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background: #ff4757;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
}

.back-home-link:hover {
  background: #ff6b81;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

