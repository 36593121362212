.about {
  max-width: 1450px;
  width: 90%;
  margin: 80px auto;
}

.about h1 {
  text-align: center;
  margin: 40px 0;
}

@keyframes sectionLoadAnimation {
          0% {
            opacity: 0;
            transform: scale(0.95);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }

.about .section {
  margin: 20px 0;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: sectionLoadAnimation 0.5s ease-in-out; /* Apply the animation */
}

.about .section h2 {
  font-size: 5rem;
  font-weight: 900;
  color: #eaeaea;
  margin: 0 0 10px 0;
  position: absolute;
  top: -20px;
  left: 0px;
  text-transform: uppercase;
}

.about .section p {
  position: relative;
  margin: 40px 0 0 0;
  font-size: 1rem;
  z-index: 2;
}
