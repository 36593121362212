.donor-container {
  max-width: 1450px;
  width: 90%;
  margin: 30px auto;
  padding: 20px;
}

.donor-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.donor-box {
  flex: 0 1 calc(20% - 20px);
  background: linear-gradient(to right, #78ffd6, #23a7c2);
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #f4f4f9; /* Light shade for better contrast */
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.donor-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.3);
}

.donor-box h2 {
  margin-top: 0;
  color: #024754;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 10px;
}

.donor-box .donor-id {
  color: #007d95;
  font-size: 0.8em;
  margin: 5px 0;
}

.donor-box .donor-trees {
  color: #024754;
  font-size: 1.2em;
  margin: 5px 0;
  font-weight: 600;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #007991; /* Dark text color for the heading */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .donor-box {
    flex: 0 1 calc(33.33% - 20px); /* 3 boxes per row */
  }
}

@media (max-width: 900px) {
  .donor-box {
    flex: 0 1 calc(50% - 20px); /* 2 boxes per row */
  }
}

@media (max-width: 600px) {
  .donor-box {
    flex: 0 1 calc(100% - 20px); /* 1 box per row */
  }
}
