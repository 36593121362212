/* NoInternet.css */

.no-internet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f0f0;
}

.no-internet-container h1 {
  font-size: 3rem;
  margin: 0.5rem 0;
}

.no-internet-container p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.no-internet-icon {
  font-size: 50px;
  color: #007991;
  position: relative;
}

.no-internet-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 2px;
  background: red;
  transform: translate(-50%, -50%) rotate(45deg);
}

