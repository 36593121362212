.farmer-container {
  max-width: 1450px;
  width: 90%;
  margin: 30px auto;
  padding: 20px;
}

.farmer-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.farmer-box {
  flex: 0 1 calc(20% - 20px);
  background: linear-gradient(to right, #78ffd6, #23a7c2);
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #f4f4f9; /* Light shade for better contrast */
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  cursor: pointer;
}

.farmer-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.3);
}

.farmer-box h2 {
  margin-top: 0;
  color: #024754;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 10px;
}

.farmer-box .farmer-id {
  color: #007d95;
  font-size: 0.8em;
  margin: 5px 0;
}

.farmer-box .farmer-location {
  color: #024754;
  font-size: 1.2em;
  margin: 5px 0;
  font-weight: 500;
}

.farmer-box .farmer-trees {
  color: #024754;
  font-size: 1.2em;
  margin: 5px 0;
  font-weight: 600;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333; /* Dark text color for the heading */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .farmer-box {
    flex: 0 1 calc(33.33% - 20px); /* 3 boxes per row */
  }
}

@media (max-width: 900px) {
  .farmer-box {
    flex: 0 1 calc(50% - 20px); /* 2 boxes per row */
  }
}

@media (max-width: 600px) {
  .farmer-box {
    flex: 0 1 calc(100% - 20px); /* 1 box per row */
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  max-height: 60%;
  animation: slideIn 0.5s;
  overflow-y: auto;
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  top: 10px;
  right: 10px;
  color: #ff0000;
  border: 1px solid #ff0000;
  background-color: #ffe0e0;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
}

.popup-box p {
  margin: 3px 0;
}

.close-button:hover {
  background-color: #ffcdcd;
}

.top-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.farmer-name {
  margin: 0;
}

.image-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.farmer-image {
  width: 100%;
  border-radius: 8px;
}

.loading-spinner {
  font-size: 24px;
  color: #000;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
  z-index: 10000;
}

.fullscreen-image-container {
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}

.fullscreen-image {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 8px;
}

.fullscreen-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #ff5c5cb0;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 26px;
}
