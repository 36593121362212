/* LoadingPopup.css */

.loading-popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
          backdrop-filter: blur(10px);
        }
        
        .loading-popup-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 8px;
          text-align: center;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
        
        .loading-popup-icon {
          font-size: 2rem;
          margin-bottom: 10px;
        }
        
        .rotating {
          animation: rotate 2s linear infinite;
        }
        
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        
        .loading-popup-text {
          font-size: 1.2rem;
          color: #333;
        }
        