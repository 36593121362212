.home {
  max-width: 1450px;
  width: 90%;
  margin: 80px auto;
}

.home h1 {
  text-align: center;
  margin: 40px 0;
}

.section {
  padding: 20px;
}

.graph-container {
  display: flex;
  justify-content: space-around;
}

.graph-section {
  width: 45%;
}

.graph-section p {
  font-size: 1rem;
  margin: 0 5px;
  font-weight: 600;
  color: #007991;
}

.graph-section p strong {
  font-weight: 900;
  color: #015a6b;
}

@keyframes sectionLoadAnimation {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.home .section {
  margin: 20px 0;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  animation: sectionLoadAnimation 0.5s ease-in-out;
}

.tips-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  margin-top: 50px;
}

.tip-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #cdf7ff;
  border: 2px solid #007991;
  border-radius: 10px;
  padding: 10px;
  width: 130px;
  height: 130px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.tip-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}
.tip-box h3 {
  margin: 0;
}

.tip-box p {
  margin: 5px auto !important;
  font-size: 13px;
}
.arrow {
  font-size: 30px;
  margin: 0 15px;
  color: #333;
}

.cursive-font {
  font-family: "Pacifico", cursive;
  font-size: 44px;
  color: #007991;
  text-align: center;
  margin-bottom: 0;
}

.home .section h2 {
  font-size: 5rem;
  font-weight: 900;
  color: #eaeaea;
  margin: 0 0 10px 0;
  position: absolute;
  top: -20px;
  left: 0px;
  text-transform: uppercase;
}

.home .section .section_p {
  position: relative;
  margin: 10px 0 30px 0;
  font-size: 1rem;
  z-index: 2;
  color: #333;
  line-height: 1.1;
  text-align: justify;
}

.home .section button {
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 20px;
}

.home-new-section h2 {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.service-box-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.service-box {
  flex: 1;
  background: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: sectionLoadAnimation 0.5s ease-in-out;
}

.service-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.5);
}

.service-icon {
  margin: 10px 0;
  width: 50%;
  height: auto;
}

.service-box h3 {
  margin-top: 10px;
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #007991;
}

.service-box p {
  margin-top: 0;
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.service-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007991;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.service-button:hover {
  background-color: #005f6b;
}

.section {
  padding: 40px;
  background: #f9f9f9;
  text-align: center;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.text-content {
  flex: 1;
  min-width: 300px;
  padding: 30px;
}

.video-content {
  flex: 1;
  min-width: 300px;
  padding: 30px;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; 
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .home {
    width: 95%;
  }
  .graph-section {
    width: 100%;
    margin: 10px 0;
  }
  .service-box-container {
    flex-direction: column;
    gap: 10px;
  }
  .home .section h2{
    font-size: 3rem;
  }
  .graph-container{
    flex-direction: column;
  }
  .graph-section {
    width: 100%;
  }
  
  .graph-section p {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .cursive-font {
    font-size: 36px;
  }
  .service-box {
    padding: 20px;
  }
  .service-box h3 {
    font-size: 1.2em;
  }
  .service-box p {
    font-size: 1em;
  }
  .team-member {
    padding: 10px;
  }
  .team-member h3 {
    font-size: 1.2em;
  }
  .team-member p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .home {
    width: 100%;
    margin: 80px 0;
  }
  .section {
    padding: 20px;
  }
  .home .section h2 {
    font-size: 1.5rem;
    top: 0;
  }
  .cursive-font {
    font-size: 28px;
  }
  .service-box {
    padding: 15px;
  }
  .service-box h3 {
    font-size: 1em;
  }
  .service-box p {
    font-size: 0.8em;
  }
  .team-member {
    padding: 10px;
  }
  .team-member h3 {
    font-size: 1em;
  }
  .team-member p {
    font-size: 0.8em;
  }
  .graph-section p {
    font-size: 1rem;
  }
  .tips-container {
    flex-direction: column;
  }
  .text-content, .video-content{
    padding: 5px;
  }
}
