.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f8ff;
  animation: backgroundFade 2s ease-in-out;
}

.thank-you-box {
  text-align: center;
  padding: 50px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: boxFadeIn 1s ease-in-out;
}

.thank-you-text {
  font-family: "Dancing Script", cursive;
  font-size: 48px;
  color: #007991;
  margin-bottom: 20px;
}

.thank-you-message {
  font-size: 18px;
  color: #333333;
  margin-bottom: 30px;
}

.redirect-button {
  background-color:  #007991;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.redirect-button:hover {
  background-color: #287a9a;
}

@keyframes backgroundFade {
  from {
    background-color: #ffffff;
  }
  to {
    background-color: #f0f8ff;
  }
}

@keyframes boxFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 2s ease-in-out;
}
