.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
  z-index: 10000;
}

.fullscreen-container {
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}

.fullscreen-image {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 8px;
}

.fullscreen-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #ff5c5cb0;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 26px;
}
