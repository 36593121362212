/* ImageGalleryGujarat.css */
.imagedisplay_container {
  max-width: 1450px;
  margin: 80px auto;
  text-align: center;
}

.imagedisplay_container h1 {
  font-size: 2.5rem;
  margin: 0;
  color: #333;
}

@keyframes imageLoadAnimation {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.imagedisplay_container .image {
  width: 200px;
  height: auto;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
  transition: transform 0.3s, box-shadow 0.3s;
  animation: imageLoadAnimation 0.5s ease-in-out; /* Apply the animation */
}

.imagedisplay_container .image:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 468px) {
  .imagedisplay_container .image{
    width: 150px;
  }
}

