.team-member-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 200px;
  margin: 20px;
}

.team-member-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-member-photo {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
}

.team-member-name {
  font-size: 1.5em;
  margin: 10px;
}

.team-member-intro {
  font-size: 1em;
  margin: 10px 20px;
}

.team-member-button {
  background: #007991;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1em;
  margin: 20px;
  padding: 10px 20px;
  transition: background 0.3s;
}

.team-member-button:hover {
  background: #005f6b;
}
