body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  display: flex;
  position: fixed;
  background-color: #fff;
  color: #000;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.3);
  z-index: 100;
  width: 100%;
  box-sizing: border-box;
}

header img {
  height: 30px;
  margin-right: 20px;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger i {
  font-size: 24px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  position: relative;
  margin-right: 20px;
}

nav ul li a, nav ul li span {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

nav ul li a i, nav ul li span i {
  margin-left: 5px;
}

nav ul li a:hover, nav ul li span:hover {
  color: #000;
  border-radius: 10px;
}

/* Dropdown styles */
.dropdown-content, .dropdown-submenu-content {
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 3;
  min-width: 160px;
  border-radius: 10px;
}

.dropdown-content a, .dropdown-submenu-content a, .dropdown-submenu span {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover, .dropdown-submenu-content a:hover {
  background-color: #f4f4f4;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Submenu styles */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu-content {
  left: 100%;
  top: 0;
}

.dropdown-submenu:hover .dropdown-submenu-content {
  display: flex;
  flex-direction: column;
}

main {
  padding: 1rem;
}

h1, h2 {
  color: #007991;
}

button {
  background-color: #007991;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #005f6b;
}

section {
  margin: 20px 0;
}

/* Existing CSS */
@media (max-width: 768px) {
  header{
    justify-content: space-between;
  }
  .hamburger {
    display: block;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 60px;
    left: 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 0 20px 20px 20px;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    margin: 10px 0;
    text-align: center;
  }

  .dropdown-content, .dropdown-submenu-content {
    position: static;
    width: 200px;
    left: 0;
  }
}
